import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Budgeting App Template | Budgeting App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/budgeting-mobile-app/"
    metaDescription="Designing your own mobile budgeting app? Uizard is here to help. Our budgeting app template comes with everything you need for your design project."
    description="
    h2:Introducing our budgeting app design template for mobile
    <br/>
    Looking to design your own budgeting app? Fear not, Uizard is here to support you with all your <a:https://uizard.io/prototyping/>UI prototyping</a> needs. Our templates are designed to fast track your design flow, so you can spend more time on branding and messaging, and less time worrying about basic design principles and UX.
    <br/>
    h3:Collaboration just got a whole lot easier
    <br/>
    Whether you are working in a team of two or a team of ten, Uizard's smart sharing features make it super easy to work on your project together. You can ideate, iterate, and prototype all in one place, meaning you could have your budgeting app design project completed in next to no time.
    <br/>
    h3:Invest in creativity, not in frustration 
    <br/>
    As with all our <a:https://uizard.io/templates/>UI templates</a>, our mobile budgeting app is 100% customizable, helping you to tailor it to your own product or vision. Use it straight out the box or spend a few seconds, minutes, hours customizing it to suit your own taste. The possibilities are endless.
    "
    pages={[
      "Onboarding screens",
      "A home dashboard with a statistics overview",
      "A detailed overview of credit card spending",
      "A transactions overview screen",
    ]}
    projectCode="BoRMAKEgWRSypg7XEZgY"
    img1={data.image1.childImageSharp}
    img1alt="mobile budgeting app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile budgeting app design profile screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile budgeting app design transactions screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile budgeting app design budget screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile budgeting app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/finance-tracking-mobeil-app/finance-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/finance-tracking-mobeil-app/finance-app-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/finance-tracking-mobeil-app/finance-app-transactions.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/finance-tracking-mobeil-app/finance-app-budget.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/finance-tracking-mobeil-app/finance-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
